import React from 'react';
import PrivateRoute from 'components/common/PrivateRoute';
import {Switch, Route} from 'react-router-dom';
import PageError from "components/layout/PageError";
import AccessControl from "pages/authorized/settings/AccessControl";
import Membership from "pages/authorized/Membership";

const Private = () => {

    return (
        <Switch>
            <PrivateRoute exact path="/dashboard" component={Membership} />
            <PrivateRoute exact path="/error" component={PageError} />
            <PrivateRoute exact path="/access_control" component={AccessControl} />
            <Route component={PageError} />
        </Switch>
    )
};

export default Private;
