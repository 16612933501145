import errorHandler from "../utils/errorHandler";
import {GET_USER_REPORT_URL, UAC_URL} from "./index";
import axios from "axios";

export const getUserReport = () => {
    return axios.get(GET_USER_REPORT_URL).then((response) => {
        return {
            payload: response.data,
            error: false
        };
    }).catch((error) => {
        errorHandler(error, "top-right");
        return {
            payload: error,
            error: true
        }
    });
};

export const admin = (method, data) => {
    return axios({method: method, url: UAC_URL, data: data}).then((response) => {
        return {
            payload: response.data.payload,
            error: false
        }
    }).catch((error) => {
        errorHandler(error, "top-right");
        return {
            payload: null,
            error: true
        }
    });
}
