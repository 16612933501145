import React, {useEffect} from "react";
import $ from "jquery";
import { Link, Redirect } from "react-router-dom";
import cogoToast from "cogo-toast";
import { encodeQueryParameter } from "../../utils/url";

const Search = (props) => {

    useEffect(() => {
        $("#query_value").keyup(function(event) {
            const error = {
                position: "top-right",
                hideAfter: 3
            };

            if (event.keyCode === 13) {
                let query = $('#query_value').val();
                if(!query) {
                    cogoToast.error("Enter Order Number.", error);
                    return false;
                } else {
                    querySearch();
                }
            }
        });
    }, []);

    const querySearch = () => {

        const error = {
            position: "top-right",
            hideAfter: 3
        };

        let query = $('#query_value').val();
        if(!query) {
            cogoToast.error("Enter client phone number.", error);
            return false;
        }

        const options = {
            position: "top-right",
            hideAfter: 0
        };

        let {hide} = cogoToast.loading('Please wait... Searching Client.', options);
    };

    return (
        <div className="content-header">
            <div className="content-search">
                <i data-feather="search"></i>
                <input type="search" id="query_value" className="form-control" placeholder="Search Client"/>
            </div>
            <nav className="nav">
                <Link to="#" onClick={querySearch.bind()} className="nav-link"><i data-feather="arrow-right-circle"></i></Link>
            </nav>
        </div>
    );
};

export default Search;
